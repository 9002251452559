import { Controller } from "stimulus"
import $ from "jquery"

export default class extends Controller {
  static targets = [ "search", "brands", "solid", "regular" ]

  connect() {
    // console.log("sprites init")
    this.rows = document.querySelectorAll(".icon-group")
    this.filter()
    // this.names = this.rows.map((row) => {
    //   return row.dataset.name
    // })
  }

  filter() {
    // console.log("sprites#filter")
    const q = new RegExp(this.searchTarget.value)
    const showBrands  = this.brandsTarget.checked
    const showSolid   = this.solidTarget.checked
    const showRegular = this.regularTarget.checked

    requestAnimationFrame(() => {
      this.rows.forEach((row) => {
        if (
          row.dataset.name.match(q) &&
          (
            (showBrands && row.classList.contains("brands")) ||
            (showSolid && row.classList.contains("solid")) ||
            (showRegular && row.classList.contains("regular"))
          )
        ) {
          row.classList.remove("d-none")
        } else {
          row.classList.add("d-none")
        }
      })
    })
  }
}
