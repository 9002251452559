let d3; // injected

class TempTimeChart {
  constructor(report, options) {
    if (options) {
      options = Object.assign(this.defaultOptions(), options)
    } else {
      options = this.defaultOptions()
    }
    this.options = options
    d3 = options.d3 || window.d3

    this.container = document.querySelector(this.options.selector)
    this.top = this.container.offsetTop
    this.labels = report.labels
    this.periods = this.buildPeriods(report)
    this.rows = this.buildRows(report)
    this.hours = this.calculateHours()
    this.height = this.calculateHeight()
    this.min = this.calculateMinTemp()
    this.max = this.calculateMaxTemp()
    // this.report = report
    this.scales = this.buildScales()
    this.svg = this.buildSvg()

    window.addEventListener("resize", () => { this.resize() })
  }

  defaultOptions() {
    return {
      selector: "#denver-hourly-chart",
      rowHeight: 15,
      width: 1000,
      margin: { top: 20, right: 10, bottom: 10, left: 50 },
      rulerYOffset: 0.5,
      periodTextWidth: 140,
      labelWidth: 100,
      tempWidth: 72,
      trHeight: 16,
    }
  }

  calculateHours() {
    let start = this.periods[0]
    let stop = this.periods[this.periods.length - 1]
    return (stop - start) / (60 * 60 * 1000)
  }

  calculateHeight() {
    let margin = this.options.margin
    let chromeHeight = margin.top + margin.bottom
    let rowHeight = this.options.rowHeight
    let rowCount = this.hours
    return (rowCount * rowHeight) + chromeHeight
  }

  calculateMinTemp() {
    return d3.min(this.rows.map(row => d3.min(row.temps)))
  }

  calculateMaxTemp() {
    return d3.max(this.rows.map(row => d3.max(row.temps)))
  }

  buildPeriods(report) {
    return report.periods.map(ts => new Date(ts * 1000))
  }

  buildRows(report) {
    return report.temps.map((lineTemps, i) => {
      return {
        label: report.labels[i],
        temps: lineTemps
      }
    })
  }

  buildSvg() {
    let selector  = this.options.selector
    let width     = this.options.width
    let height    = this.height
    let svg = d3.select(selector).append("svg")
        .attr("viewBox", [0, 0, width, height])

    this.svg = svg
    this.crossbar = this.buildCrossbar(svg)

    svg.append("g").call(this.buildXAxis())
    svg.append("g").call(this.buildYAxis())
    svg.call(this.mouseWatcher())

    this.buildChartLines(svg)
  }

  buildScales(){
    let periods   = this.periods
    let labels    = this.labels
    let width     = this.options.width
    let height    = this.height
    let margin    = this.options.margin
    let rowHeight = this.options.rowHeight
    let minTemp   = this.calculateMinTemp()
    let maxTemp   = this.calculateMaxTemp()
    return {
      y: d3.scaleTime()
          .domain(d3.extent(periods))
          .range([margin.top, height - margin.bottom]),
      x: d3.scaleLinear()
          .domain(d3.extent([minTemp, maxTemp]))
          .range([margin.left, width - margin.right]),
      periods: d3.scaleQuantize()
          .domain(d3.extent([margin.top, (height - margin.bottom) + rowHeight]))
          .range(periods),
      nearest: d3.scaleQuantize()
          .domain(d3.extent(periods))
          .range(periods),
      colors: d3.scaleOrdinal()
          .domain(labels)
          .range(d3.schemeBlues[d3.min([9, labels.length])])
    }
  }

  buildXAxis() {
    let top = this.options.margin.top
    let axis = d3.axisTop(this.scales.x)
    return (g) => {
      g.attr("transform", `translate(0,${top})`)
          .call(axis)
          .call(g => g.select(".domain").remove())
          .call(g => g.selectAll(".tick text").text(t => `${t}°F`))
    }
  }

  buildYAxis() {
    let left = this.options.margin.left
    let axis = d3.axisLeft(this.scales.y)
        .ticks(this.hours / 5)
    return (g) => {
      g.attr("transform", `translate(${left},0)`)
          .call(axis)
          .call(g => g.select(".domain").remove())
    }
  }

  buildCrossbar(svg) {
    let rulerOffset = this.options.rulerYOffset
    let bar = svg.append("g").attr("opacity", 0)
    let ruler = d3.line().x(pos => pos.x).y(pos => pos.y + rulerOffset)
    let margin = this.options.margin
    let width = this.options.width

    bar.append("path")
        .attr("fill", "none")
        .attr("stroke", "red")
        .attr("stroke-width", 1)
        .datum([
          { x: margin.left, y: 0 },
          { x: width - margin.right, y: 0 }
        ])
        .attr("d", ruler)

    let bartop = bar.append("text")
        .attr("class", "bartop")
        .attr("font-size", "11px")
        .attr("text-anchor", "end")
        .attr("y", -2)

    bartop.append("tspan")
        .attr("class", "period")
        .attr("fill", "#bb0000")

    bartop.append("tspan")
        .attr("class", "actual")
        .attr("fill", "#5fb34a")
        .attr("font-weight", "bold")

    this.buildBartab(svg)

    return bar
  }

  buildBartab(svg) {
    let labels = this.labels
    let stop = labels.length - 1
    let columns = 2
    let numRows = Math.ceil(stop / columns)
    let rowHeight = 15
    let legendWidth = 12
    let labelWidth = 30
    let tempWidth = 64
    let i = 0
    let col = -1
    let bartab = svg.append("g")
        .attr("class", "bartab")
        .attr("font-size", "10px")
        .attr("opacity", 0)
        // .attr("display", "none")
    let bartemps = []
    let tCol = null
    let tRow = null
    let left = 0
    let top = 0
    for (i = 0; i < stop; i++) {
      if (Math.floor(i / numRows) > col) {
        col = Math.floor(i / numRows)
        tCol = bartab.append("g")
            .attr("class", "bartab-column")
            .attr("id", `bartab-col-${col}`)
            // .attr("x", ((labelWidth + tempWidth) * col) + this.options.margin.left)
      }
      left = (labelWidth + tempWidth) * col
      top = (rowHeight * ((i % numRows) + 1)) - 2
      tRow = tCol.append("text")
          .attr("fill", this.scales.colors(labels[i]))
          .attr("class", "bartab-row")
      tRow.append("tspan")
          .attr("class", "bartab-legend")
          .attr("text-anchor", "start")
          .attr("x", left)
          .attr("y", top)
          .text("—")
      tRow.append("tspan")
          .attr("class", "bartab-label")
          .attr("text-anchor", "end")
          .attr("x", (left + legendWidth + labelWidth) - 2)
          .attr("y", top)
          .text(`${labels[i]}:`)
      bartemps.push(
        tRow.append("tspan")
            .attr("class", "bartab-temp")
            .attr("text-anchor", "start")
            .attr("x", left + legendWidth + labelWidth)
            .attr("y", top)
            .text("55°F")
      )
    }
    this.bartab = bartab
    this.bartemps = bartemps
  }

  buildChartLines(svg) {
    let rows    = this.rows
    let periods = this.periods
    let colors  = this.scales.colors
    let x       = this.scales.x
    let y       = this.scales.y
    let actual  = rows.length - 1
    let line = d3.line()
        .x(t => x(t))
        .y((_t, i) => y(periods[i]))

    svg.append("g")
        .attr("fill", "none")
        .attr("stroke-width", 1)
        .selectAll("path")
        .data(rows)
        .join("path")
          .attr("stroke", ({label}) => colors(label))
          .attr("d", row => line(row.temps))
          .attr("title", row => row.label)
          .attr("id", (row, i) => `temp-path-${i}`)

    svg.select(`#temp-path-${actual}`)
        .attr("stroke-width", 3)
        .attr("stroke", "#5fb34a")
  }

  nearestPeriod(time) {
    let right = d3.bisectLeft(this.periods, time)
    let left = right - 1
    if (left < 0) { return right }
    if (right == this.periods.length) { return left }
    if (Math.abs(time - this.periods[left]) < Math.abs(this.periods[right] - time)) {
      return left
    } else {
      return right
    }
  }

  mouseWatcher() {
    let crossbar = this.crossbar
    let bartop = crossbar.select(".bartop")
    let bartab = this.bartab
    let actualText = bartop.select(".actual")
    let periodText = bartop.select(".period")
    let periodTextWidth = this.options.periodTextWidth
    let margin = this.options.margin
    let width = this.options.width
    let rowHeight = this.options.rowHeight

    let over = () => {
      let ratio = this.ratio()
      let y = (d3.event.layerY - this.top) / ratio
      let i = this.nearestPeriod(this.scales.y.invert(y))
      let period = this.periods[i]
      let transY = this.scales.y(period)
      let temp = this.rows[this.rows.length - 1].temps[i]
      let right = width - margin.right
      let legendY = d3.min([
        d3.max([transY, margin.top + rowHeight]),
        this.height - margin.bottom - (rowHeight * 3)
      ])
      console.log([i, period, transY, legendY])
      crossbar.transition()
          .duration(100)
          .ease(d3.easeLinear)
          .attr("transform", `translate(0,${transY})`)
          .attr("opacity", 1)
      periodText.text(period.toLocaleString())
      actualText.text(`Actual Temp: ${temp}°F`)
      if (temp > d3.mean([this.min, this.max])) {
        bartop.attr("text-anchor", "start")
            .transition()
            .duration(100)
            .ease(d3.easeLinear)
            .attr("y", (legendY - transY) - 2)
        // bartop.attr("text-anchor", "start")
        // bartop.attr("y", (legendY - transY) - 2)
        periodText.attr("x", margin.left)
        actualText.attr("x", margin.left + periodTextWidth)
        bartab.transition()
            .duration(100)
            .ease(d3.easeLinear)
            .attr("transform", `translate(${margin.left + 10},${legendY})`)
            .attr("opacity", 1)
        // bartab.attr("transform", `translate(${margin.left + 10},${legendY})`)
      } else {
        bartop.attr("text-anchor", "end")
            .transition()
            .duration(100)
            .ease(d3.easeLinear)
            .attr("y", (legendY - transY) - 2)
        periodText.attr("x", right)
        actualText.attr("x", right - periodTextWidth)
        // bartab.attr("transform", `translate(${right - 180},${legendY})`)
        bartab.transition()
            .duration(100)
            .ease(d3.easeLinear)
            .attr("transform", `translate(${right - 180},${legendY})`)
            .attr("opacity", 1)
      }
      this.bartemps.forEach((bartemp, ti) => {
        bartemp.text(`${this.rows[ti].temps[i]}°F`)
      })
    }
    let enter = () => {
      // crossbar.attr("display", null)
      // bartab.attr("display", null)
    }
    let leave = () => {
      // crossbar.attr("display", "none")
      // bartab.attr("display", "none")
      crossbar.transition().duration(500).attr("opacity", 0)
      bartab.transition().duration(500).attr("opacity", 0)
    }
    return (svg) => {
      svg.on("mousemove", over)
      svg.on("mouseenter", enter)
      svg.on("mouseleave", leave)
    }
  }

  resize() {
    this.setRatio()
  }

  setRatio() {
    this._ratio = this.container.clientWidth / this.options.width
  }

  ratio() {
    if (!this._ratio) { this.setRatio() }
    return this._ratio
  }
}

export default TempTimeChart
