import consumer from "./consumer"

export default class {
  constructor() {
    this.pendingRefresh = false
    this.disconnecting = false
  }

  check(forecasts) {
    this.sendActiveForecasts(forecasts)
  }

  connect() {
    this.disconnecting = false
    if (!this.promise) {
      this.promise = this.connectPromise()
    }
    return this.promise
  }

  disconnect() {
    this.disconnecting = true
    setTimeout(() => {
      if (this.disconnecting) {
        console.log("Unsubscribing from WeatherChannel")
        this.subscription.unsubscribe()
        this.promise = null
      }
    }, 500)
  }

  connectPromise() {
    let channel = this
    return new Promise((resolve, reject) => {
      channel.subscription = consumer.subscriptions.create({ channel: "WeatherChannel" }, {
        connected() {
          resolve(channel.subscription)
        },
        received(data) {
          if (data["refreshed"]) {
            channel.refresh()
          }
        }
      })
    })
  }

  refresh() {
    if (document.visibilityState === "visible") {
      this.pendingRefresh = false
      Turbolinks.clearCache()
      Turbolinks.visit(window.location.href, { action: "replace" })
    } else {
      this.pendingRefresh = true
    }
  }

  async sendActiveForecasts(forecasts) {
    let sub = await this.connect()
    sub.perform("check_forecasts", { forecasts })
  }
}
