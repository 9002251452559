import { Controller } from "stimulus"

export default class extends Controller {
  static targets = []

  connect() {
    this.body = document.getElementsByTagName("body")[0]
    this.currentState = 0
    console.log(this)
    this.body.classList.add("clear")
    this.body.addEventListener("click", () => {
      this.nextColor()
    })
  }

  nextColor() {
    const states = [null, "black", "white", "red", "green", "blue"]
    const currentClass = states[this.currentState]
    this.currentState += 1
    if (this.currentState == states.length) this.currentState = 0
    const nextClass = states[this.currentState]
    console.log(["nextColor", currentClass, nextClass])

    if (currentClass) { this.body.classList.remove(currentClass) }
    if (nextClass) { this.body.classList.add(nextClass) }
  }
}
