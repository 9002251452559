class NwsWeatherWatcher {
  constructor(channel) {
    this.interval = 10000
    this.channel = channel
    this.enabled = false
    this.scheduled = false

    window.addEventListener("focus", () => {
      if (channel.pendingRefresh) { channel.refresh() }
    })
  }

  enable() {
    this.enabled = true
    this.startSchedule()
  }

  startSchedule() {
    if (this.scheduled) { return }

    this.schedule(10)
  }

  schedule(delay) {
    delay = delay || this.interval
    this.scheduled = true
    setTimeout(() => {
      if (this.enabled) { this.sendForecastLocations() }
      if (this.scheduled) { this.schedule() }
    }, delay)
  }

  disable(scheduleToo) {
    this.enabled = false
    if (scheduleToo) { this.scheduled = false }
  }

  sendForecastLocations() {
    let locations = this.readForecastLocationsFromDOM()
    if (locations.length > 0) {
      this.channel.check(locations)
    }
  }

  readForecastLocationsFromDOM() {
    let locations = []
    document.querySelectorAll(".forecastLocation").forEach((div) => {
      locations.push({
        location_id: div.dataset.locationId,
        forecast_id: div.dataset.forecastId
      })
    })
    return locations
  }
}

export default NwsWeatherWatcher
