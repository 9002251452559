import { Controller } from "stimulus"

import Chart from "../components/temp_time_chart"

export default class extends Controller {
  static targets = []

  initialize() {
  }

  connect() {
    if (window.HourlyChartData) {
      window.hourlyChart = new Chart(HourlyChartData, {
        d3: window.d3,
        selector: "#hourly-chart"
      })
    }
  }

  disconnect() {

  }
}
