import { Controller } from "stimulus"

export default class extends Controller {
  static targets = []

  connect() {
    console.log(["connected", window.DenverHourly])
    let lines = []
    window.DenverHourly.temps.forEach((temps, i) => {
      lines.push({
        x: window.DenverHourly.periods.map(ts => new Date(ts * 1000)),
        y: temps,
        type: "scatter",
        name: window.DenverHourly.labels[i]
      })
    })
    Plotly.newPlot("denver-hourly-chart", lines)
  }

  disconnect() {

  }
}
