import { Controller } from "stimulus"
import $ from "jquery"
import NwsWeatherChannel from "../channels/nws_weather_channel"
import NwsWeatherWatcher from "../components/nws_weather_watcher"

let channel = new NwsWeatherChannel()
let watcher = new NwsWeatherWatcher(channel)

export default class extends Controller {
  static targets = []

  initialize() {
  }

  connect() {
    console.log("NwsWeatherForecastsController#connect")
    watcher.enable()
    channel.connect()
  }

  disconnect() {
    console.log("NwsWeatherForecastsController#disconnect")
    watcher.disable()
    channel.disconnect()
  }
}
