import { Controller } from "stimulus"

import Chart from "../components/temp_time_chart"

export default class extends Controller {
  static targets = []

  initialize() {
  }

  connect() {
    window.currentChart = new Chart(DenverHourly, { d3: window.d3 })
  }

  disconnect() {

  }
}
