import { Controller } from "stimulus"
import $ from "jquery"

export default class extends Controller {
  static targets = []

  connect() {
    // console.log("pages init")
    // $('[data-toggle="tooltip"]').tooltip()
    // console.log(this.scope.element)
    // console.log("pages init complete")
  }
}
