import { Controller } from "stimulus"
import $ from "jquery"
import WeatherChannel from "../channels/weather_channel"
import WeatherWatcher from "../components/weather_watcher"

let channel = new WeatherChannel()
let watcher = new WeatherWatcher(channel)

export default class extends Controller {
  static targets = []

  initialize() {
  }

  connect() {
    // console.log("WeatherForecastsController#connect")
    watcher.enable()
    channel.connect()
  }

  disconnect() {
    // console.log("WeatherForecastsController#disconnect")
    watcher.disable()
    channel.disconnect()
  }
}
