class WeatherWatcher {
  constructor(channel) {
    this.sendForecastsDelay = 10000
    this.channel = channel
    this.last = Date.now() - this.sendForecastsDelay
    this.enabled = false
    this.scheduled = false

    window.addEventListener("focus", () => {
      if (channel.pendingRefresh) {
        channel.refresh()
      }
    })
  }

  enable() {
    this.enabled = true
    if (!this.scheduled) {
      this.startSchedule()
    }
  }

  startSchedule() {
    let now = Date.now()
    let nextSend = this.last + this.sendForecastsDelay
    if (now >= nextSend) {
      nextSend = now + this.sendForecastsDelay
      this.sendForecasts()
    } else {
      this.schedule(nextSend - now)
    }
  }

  schedule(delay) {
    delay = delay || this.sendForecastsDelay
    this.scheduled = true
    setTimeout(() => {
      this.sendForecasts()
    }, delay)
  }

  disable() {
    this.enabled = false
  }

  sendForecasts() {
    let forecasts = this.readForecastsFromDOM()
    if (this.enabled) {
      if (forecasts.length > 0) {
        this.last = Date.now()
        this.channel.check(forecasts)
      }
      this.schedule()
    } else {
      this.scheduled = false
    }
  }

  readForecastsFromDOM() {
    let forecasts = []
    document.querySelectorAll(".forecast").forEach((div) => {
      forecasts.push({
        id: +div.dataset.forecastId,
        refreshed_at: +div.dataset.refreshedAt
      })
    })
    return forecasts
  }
}

export default WeatherWatcher
